// @flow
import * as React from 'react'

import { Currency, Percent } from 'i18n/NumberFormatter'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'

import transDomain from './translations/index.translations'
import { Store } from 'Store'

import AbsoluteTime from 'components/AbsoluteTime'
import EventLogo from 'components/MainLogo/EventLogo'
import EventLogoFr from 'components/MainLogo/EventLogoFr'
import { wrapText } from '../Form/Helpers'

type Props = {
    width: string, // eg. "541px"
    locale: string,
    lead: { [string]: mixed },
    campaign: { [string]: mixed },
}

const Voucher = React.forwardRef((props: Props, ref: React.Ref) => {
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const isFr = state.locale.language === 'fr'
    const { lead, campaign, locale, currency } = state
    const { theme } = props
    const currencyFormatter = Currency(currency, locale)
    const percentFormatter = Percent(locale)
    const manufacturerIncentives = campaign.incentives.manufacturer

    const terms = wrapText(
        campaign.incentives.voucher.terms.replace('[dealership_name]', campaign.client.name),
        205
    )

    return (
        <svg ref={ref} width={props.width} viewBox="0 0 541 216" version="1.1">
            <defs>
                <linearGradient x1="50%" y1="20.5741808%" x2="50%" y2="100%" id="linearGradient-1">
                    <stop stopColor={theme.leftGradient.top} offset="0%"></stop>
                    <stop stopColor={theme.leftGradient.bottom} offset="100%"></stop>
                </linearGradient>
                <rect id="path-2" x="0" y="0" width="531" height="206" rx="4"></rect>
            </defs>
            <g>
                <rect id="Rectangle" fill={theme.secondary} x="0" y="0" width="541" height="216" rx="8"></rect>
                <g id="Rectangle" transform="translate(5.000000, 5.000000)">
                    <mask id="mask-3" fill="white">
                        <use xlinkHref="#path-2"></use>
                    </mask>
                    <use id="Mask" fill="url(#linearGradient-1)" xlinkHref="#path-2"></use>
                    <rect fill="#FFFFFF" mask="url(#mask-3)" x="310" y="-3" width="226" height="214"></rect>
                    <rect fill="#FFFFFF" mask="url(#mask-3)" x="-5" y="174" width="541" height="37"></rect>
                </g>
            </g>
            <g transform="translate(0.000000, 159.000000)">
                <g fill={theme.secondary}>
                    <rect x="0" y="0" width="541" height="23"></rect>
                </g>
                <g transform="translate(5.000000, 0.000000)" fontWeight="normal" letterSpacing="-0.100000001">
                    <text x="264" y="15" textAnchor="middle" fontSize="10" fill={theme.voucherInfo}>
                        {t('redeemable', {
                            dealershipName: campaign.client.name,
                            name: campaign.incentives.voucher.name
                        })} &nbsp;
                        <AbsoluteTime noContainer dateFormat='long' date={campaign.expiryDate} />
                    </text>
                    <text fontSize={'5'} fill={theme.textColor} textAnchor='middle'>
                        {terms.map((line, i) => (
                            <tspan x="50%" y={(terms.length > 2 ? 32 : 34) + i * (terms.length > 2 ? 7 : 8)}>{line}</tspan>
                        ))}
                    </text>
                </g>
                {theme.manufacturerLogo && (
                    <g transform="translate(493.000000, 31.000000)">
                        <image x={theme.manufacturerLogoStyle.x} y={theme.manufacturerLogoStyle.y} width={theme.manufacturerLogoStyle.width} xlinkHref={theme.manufacturerLogo}></image>
                    </g>
                )}
            </g>

            {manufacturerIncentives && (
                <g transform="translate(315.000000, 82.000000)" fontWeight="normal">
                    {manufacturerIncentives.cashBack !== 0 && (
                        <g transform={`translate(${manufacturerIncentives.interestRate != null ? 0 : 60}, 32.000000)`}>
                            <text fontSize="7" fill={theme.textColor}>
                                <tspan y="8" x={isFr ? '16.4260254' : '26.4260254'}>{t('cash_back')}</tspan>
                            </text>

                            { manufacturerIncentives.interestRate !== null && (
                                <text fontSize={campaign.incentives.manufacturer.areCombinable ? '18' : '11'} fill={theme.textColor}>
                                    {isFr
                                        ? <>
                                            <tspan></tspan>
                                        </>
                                        : <>
                                            <tspan textAnchor="middle" x="112" y="26">{campaign.incentives.manufacturer.areCombinable ? '+' : 'OR'}</tspan>
                                        </>}
                                </text>
                            )}
                            <text fontSize="21" letterSpacing="-0.699999988" fill={theme.ternary}>
                                <tspan textAnchor="middle" x="56" y="30" fontSize="23">{`${currencyFormatter.format(campaign.incentives.manufacturer.cashBack)}`}</tspan>
                            </text>
                        </g>
                    )}

                    {manufacturerIncentives.interestRate !== null && (
                        <g transform={`translate(${manufacturerIncentives.cashBack !== 0 ? 111 : 58}, 32.000000)`}>
                            <text id="0.99%" fontSize="23" letterSpacing="-0.699999988" fill={theme.ternary}>
                                <tspan textAnchor="middle" x="56" y="30">
                                    {percentFormatter.format(campaign.incentives.manufacturer.interestRate / 100)}
                                </tspan>
                            </text>
                            <text id="Rates-as-low-as" fontSize="7" fill={theme.textColor}>
                                <tspan x={isFr ? '24.4260254' : '31.2949219'} y="8">{t('rates')}</tspan>
                            </text>
                        </g>
                    )}
                    <g fill={theme.textColor} fontSize="10">
                        <text>
                            <tspan textAnchor="middle" x="112" y="10">{t('combine.1')}</tspan>
                            <tspan textAnchor="middle" x="112" y="23">{t('combine.2')}</tspan>
                        </text>
                    </g>
                </g>
            )}

            <svg x="10" y="20" height="125" width="300" fontWeight="normal">
                <text>
                    <tspan fontSize="11" fill={theme.textColor} textAnchor="middle" x="50%" dy="0" dominantBaseline="hanging">
                        {t('voucher.prefix', { name: lead.fields.firstName })}
                    </tspan>
                </text>

                {lead.incentives.voucher.value > 0 ? (<>
                    <text fill={theme.primary} textAnchor="middle" x="50%" y="92" fontSize="80" letterSpacing="-2">
                        {currencyFormatter.format(lead.incentives.voucher.value)}
                    </text>

                    <text fontSize="11" fill={theme.textColor} textAnchor="middle" x="50%" dy="24">
                        {t('voucher.name', { name: campaign.incentives.voucher.name })}
                    </text>
                </>) : (
                    <text fill={theme.primary} x="50%" y="50px" textAnchor="middle" dominantBaseline='middle'>
                        {wrapText(campaign.incentives.voucher.name).map((line, index) => (
                            <tspan fontSize="32" letterSpacing="-1" fontFamily="Oxygen" fontWeight="bold" x="50%" dy={index * 32}>
                                {line}
                            </tspan>
                        ))}
                    </text>
                )}

                <text fontSize="11" fill={theme.textColor}>
                    {isFr
                        ? (
                            <>
                                <tspan textAnchor="middle" x="140" y="110.662598">{t('voucher.suffix.1')}</tspan>
                                <tspan textAnchor="middle" x="140" y="122.662598">{t('voucher.suffix.2',
                                    {
                                        year: state.lead.fields.newVehicleModelYear,
                                        make: state.lead.fields.newVehicleMake,
                                        model: state.lead.fields.newVehicleModel
                                    }
                                )}
                                </tspan>
                            </>
                        )
                        : (
                            <>
                                <tspan textAnchor="middle" x="50%" y="123.662598">{t('voucher.suffix',
                                    {
                                        year: state.lead.fields.newVehicleModelYear,
                                        make: state.lead.fields.newVehicleMake,
                                        model: state.lead.fields.newVehicleModel
                                    }
                                )}
                                </tspan>
                            </>
                        )
                    }
                </text>
            </svg>

            <g transform={manufacturerIncentives ? 'translate(342.000000, 18.000000)' : 'translate(342.000000, 50.000000)'}>
                {isFr
                    ? <>
                        <EventLogoFr ref={props.innerLogoRef} primaryColor={theme.eventLogoColor} secondaryColor="#000" x="12.5" y="-80" width="150" />
                    </>
                    : <>
                        <EventLogo ref={props.innerLogoRef} primaryColor={theme.eventLogoColor} secondaryColor="#000" x="27" y="-80" width="114" />
                        <text fontSize="7" fontWeight="normal" fill="#000">
                            <tspan textAnchor="middle" x="85" y="3">{campaign.client.name}</tspan>
                        </text>
                    </>
                }
            </g>
        </svg>
    )
})

Voucher.defaultProps = {
    width: '541px'
}

export default withTheme(supportedThemes)(Voucher)
