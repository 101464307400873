// @flow
import * as React from 'react'
import cn from 'classnames'

import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import { Currency, Percent } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

function CashbackAndRates (props) {
    const { theme, t } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const percentFormatter = Percent(state.locale)
    const manufacturerIncentives = state.campaign.incentives.manufacturer

    if (!manufacturerIncentives) return null

    const hiddenCashBack = state.campaign.incentives.manufacturer.cashBack === 0

    return (
        <div className={cn(styles.container, props.className, {
            [styles.areCombinable]: manufacturerIncentives.areCombinable,
            [styles.hiddenCashBack]: hiddenCashBack || manufacturerIncentives.interestRate === null,
        })} style={{ backgroundColor: theme.background }}>
            {manufacturerIncentives.cashBack && (
                <div className={styles.cashBack}>
                    <span className={styles.cashBackTitle} style={{ color: theme.titleColor }}>
                        <Trans t={t} i18nKey="cash_back">Combine with <br/><strong>Cash Incentives</strong> up to</Trans>
                    </span>
                    <span className={cn(styles.cashBackValue, { [styles.smallValues]: props.small })} style={{ color: theme.valueColor }}>
                        {currencyFormatter.format(manufacturerIncentives.cashBack)}
                    </span>
                </div>
            )}

            {manufacturerIncentives.interestRate !== null && (
                <div className={styles.rates}>
                    <span className={styles.ratesTitle} style={{ color: theme.titleColor }}>
                        <Trans t={t} i18nKey="rates">Don't forget about<br /><span> our </span><strong>Rates</strong> as low as</Trans>
                    </span>
                    <span className={cn(styles.ratesValue, { [styles.smallValues]: props.small })} style={{ color: theme.valueColor }}>
                        {percentFormatter.format((Number.parseFloat(manufacturerIncentives.interestRate).toPrecision(3) / 100) || 0)}
                    </span>
                </div>
            )}
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(CashbackAndRates))
