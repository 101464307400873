// @flow
import * as React from 'react'

import { Currency, Percent } from 'i18n/NumberFormatter'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import EventLogoFr from 'components/MainLogo/EventLogoFr'

import transDomain from './translations/index.translations'
import { Store } from 'Store'

import AbsoluteTime from 'components/AbsoluteTime'
import EventLogo from 'components/MainLogo/EventLogo'
import { wrapText } from '../Form/Helpers'

type Props = {
    width: string, // eg. "541px"
    locale: string,
    campaign: { [string]: mixed },
}

PromoVoucher.defaultProps = {
    width: '541px'
}

function PromoVoucher (props: Props) {
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const { campaign, locale, currency } = state
    const isFr = state.locale.language === 'fr'
    const { theme } = props
    const currencyFormatter = Currency(currency, locale)
    const percentFormatter = Percent(locale)
    const manufacturerIncentives = state.campaign.incentives.manufacturer

    const terms = wrapText(
        campaign.incentives.voucher.terms.replace('[dealership_name]', campaign.client.name),
        180
    )

    return (
        <svg width={props.width} viewBox="0 0 541 216" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient x1="50%" y1="20.5741808%" x2="50%" y2="100%" id="linearGradient-1">
                    <stop stopColor={theme.leftGradient.top} offset="0%"></stop>
                    <stop stopColor={theme.leftGradient.bottom} offset="100%"></stop>
                </linearGradient>
                <rect id="path-2" x="0" y="0" width="531" height="206" rx="4"></rect>
            </defs>
            <g>
                <rect id="Rectangle" fill={theme.secondary} x="0" y="0" width="541" height="216" rx="8"></rect>
                <g id="Rectangle" transform="translate(5.000000, 5.000000)">
                    <mask id="mask-3" fill="white">
                        <use xlinkHref="#path-2"></use>
                    </mask>
                    <use id="Mask" fill="url(#linearGradient-1)" xlinkHref="#path-2"></use>
                    <rect fill="#FFFFFF" mask="url(#mask-3)" x="310" y="-3" width="226" height="214"></rect>
                    <rect fill="#FFFFFF" mask="url(#mask-3)" x="-5" y="174" width="541" height="37"></rect>
                </g>
            </g>
            <g transform="translate(0.000000, 159.000000)">
                <g fill={theme.secondary}>
                    <rect x="0" y="0" width="541" height={isFr ? '27' : '23'}></rect>
                </g>
                <g transform="translate(5.000000, 0.000000)" fontWeight="normal" letterSpacing="-0.100000001">
                    <text x="40" y="15" textAnchor="middle" fontSize={isFr ? '8' : '10'} fill="#FFFFFF">
                        {isFr
                            ? <>
                                <tspan x="40" y="11.5">{t('visit.1')}</tspan>
                                <tspan x="40" y="21.5" >{t('visit.2')}</tspan>
                            </>
                            : <> {t('visit') }</>
                        }
                    </text>
                    <rect fill="#EFF9FC" x="80" y="3" width="263" height="17" rx="2"></rect>
                    <text fontFamily="Oxygen-Regular, Oxygen" fontSize="8.5" fontWeight="normal" letterSpacing="-0.100000001" fill={theme.primary}>
                        <tspan textAnchor="middle" x="211.408472" y="14">{window.location.host}/{window.location.pathname.split('/')[1]}</tspan>
                    </text>
                    <text x="397" y="15" textAnchor="middle" fontSize={isFr ? '8' : '10'} fill="#FFFFFF">
                        {isFr
                            ? <>
                                <tspan x="397" y="11.5">{t('promo_code.1')}</tspan>
                                <tspan x="390" y="21.5" >{t('promo_code.2')}</tspan>
                            </>
                            : <>{t('promo_code')}</>
                        }
                    </text>
                    <rect fill="#EFF9FC" x="450" y="3" width="74" height="17" rx="2"></rect>
                    <text fontFamily="Oxygen-Regular, Oxygen" fontSize="8.5" fontWeight="normal" letterSpacing="-0.100000001" fill={theme.primary}>
                        <tspan textAnchor="middle" x="488" y="14">{ props.promoCode }</tspan>
                    </text>
                    <text fontSize='5' fill={theme.textColor} textAnchor='start'>
                        {terms.map((line, i) => (
                            <tspan x="10" y={(terms.length > 2 ? 32 : 34) + i * (terms.length > 2 ? 7 : 8)} key={i}>{line}</tspan>
                        ))}
                    </text>
                </g>
                <g transform="translate(493.000000, 31.000000)">
                    <image x={theme.manufacturerLogoStyle.x} y={theme.manufacturerLogoStyle.y} width={theme.manufacturerLogoStyle.width} xlinkHref={theme.manufacturerLogo}></image>
                </g>
            </g>
            {manufacturerIncentives && (
                <g transform="translate(315.000000, 82.000000)" fontWeight="normal">
                    {(manufacturerIncentives.cashBack !== 0) && (
                        <g transform={`translate(${manufacturerIncentives.interestRate !== null ? 0 : 60}, 32.000000)`}>
                            <text fontSize="7" fill={theme.textColor}>
                                <tspan x={isFr ? '16.4260254' : '22.4260254'} y="8">{t('cash_back')}</tspan>
                            </text>

                            {(manufacturerIncentives.interestRate !== null) && (
                                <text fontSize={campaign.incentives.manufacturer.areCombinable ? '18' : '11'} fill={theme.textColor}>
                                    {isFr ? (
                                        <tspan></tspan>
                                    ) : (
                                        <tspan textAnchor="middle" x="112" y="26">{campaign.incentives.manufacturer.areCombinable ? '+' : 'OR'}</tspan>
                                    )}
                                </text>
                            )}

                            <text fontSize="21" letterSpacing="-0.699999988" fill={theme.ternary}>
                                <tspan textAnchor="middle" x="56" y="30" fontSize="23">{`${currencyFormatter.format(campaign.incentives.manufacturer.cashBack)}`}</tspan>
                            </text>
                        </g>
                    )}

                    {(manufacturerIncentives.interestRate !== null) && (
                        <g transform={`translate(${manufacturerIncentives.cashBack !== 0 ? 111 : 58}, 32.000000)`}>
                            <text id="0.99%" fontSize="23" letterSpacing="-0.699999988" fill={theme.ternary}>
                                <tspan textAnchor="middle" x="56" y="30">
                                    {percentFormatter.format(campaign.incentives.manufacturer.interestRate / 100)}
                                </tspan>
                            </text>
                            <text id="Rates-as-low-as" fontSize="7" fill={theme.textColor}>
                                <tspan x={isFr ? '24.4260254' : '30.2949219'} y="8">{t('rates')}</tspan>
                            </text>
                        </g>
                    )}

                    <g fill={theme.textColor} fontSize="10">
                        <text>
                            <tspan textAnchor="middle" x="112" y="10">{t('combine.1')}</tspan>
                            <tspan textAnchor="middle" x="112" y="23">{t('combine.2')}</tspan>
                        </text>
                    </g>
                </g>
            )}
            <svg x="10" y="20" height="125" width="300">
                {isFr
                    ? <>
                        <text fontSize="10" fill={theme.textColor}>
                            <tspan textAnchor="middle" x="110" y="8.66259766">
                                {t('voucher.prefix.1')}
                                <tspan fontWeight="600"><AbsoluteTime noContainer dateFormat='long' date={campaign.expiryDate}/></tspan>
                            </tspan>
                            <tspan textAnchor="middle" x="232" y="8.66259766">{t('voucher.prefix.2', {
                                voucher: state.campaign.incentives.voucher.name,
                                type: state.campaign.incentives.voucher.type
                            })}</tspan>
                            <tspan textAnchor="middle" x="140" y="20.66259766">{t('voucher.prefix.3', {
                                name: state.campaign.incentives.voucher.name
                            })}</tspan>
                        </text>
                        {campaign.incentives.voucher.value > 0 ? (
                            <text
                                fill={theme.primary}
                                x="50%"
                                y="68px"
                                textAnchor="middle"
                                dominantBaseline='middle'
                                fontSize="72"
                                letterSpacing="-2"
                                fontFamily="Oxygen"
                                fontWeight="bold">
                                {currencyFormatter.format(campaign.incentives.voucher.value)}
                            </text>
                        ) : (
                            <text fill={theme.primary} x="50%" y="50px" textAnchor="middle" dominantBaseline='middle'>
                                {wrapText(campaign.incentives.voucher.name).map((line, index) => (
                                    <tspan fontSize="32" letterSpacing="-1" fontFamily="Oxygen" fontWeight="bold" x="50%" dy={index * 32}>
                                        {line}
                                    </tspan>
                                ))}
                            </text>
                        )}
                    </>
                    : <>
                        <text fontSize="10" fill={theme.textColor}dominant-baseline='hanging' text-anchor='middle'>
                            <tspan textAnchor="middle" x="50%" dy="0">
                                {t('voucher.prefix.0')}
                                <AbsoluteTime noContainer dateFormat='long' date={campaign.expiryDate}/>
                            </tspan>
                            <tspan textAnchor="middle" x="50%" dy="12">{t('voucher.prefix.1', {
                                voucher: state.campaign.incentives.voucher.name,
                                type: state.campaign.incentives.voucher.type,
                                value: state.campaign.incentives.voucher.value })}
                            </tspan>
                        </text>

                        {campaign.incentives.voucher.value > 0 ? (
                            <text
                                fill={theme.valueColor || theme.primary}
                                x="50%"
                                y="72px"
                                textAnchor="middle"
                                dominantBaseline='middle'
                                fontSize="72"
                                letterSpacing="-2"
                                fontFamily="Oxygen"
                                fontWeight="bold">
                                {currencyFormatter.format(campaign.incentives.voucher.value)}
                            </text>
                        ) : (
                            <text fill={theme.primary} x="50%" y="50px" textAnchor="middle" dominantBaseline='middle'>
                                {wrapText(campaign.incentives.voucher.name).map((line, index) => (
                                    <tspan fontSize="32" letterSpacing="-1" fontFamily="Oxygen" fontWeight="bold" x="50%" dy={index * 32}>
                                        {line}
                                    </tspan>
                                ))}
                            </text>
                        )}
                    </>
                }
                <text fontSize="10" fill={theme.textColor}>
                    {isFr ? <>
                        <tspan textAnchor="middle" x="140" y="108.662598">{t('voucher.suffix.1')}</tspan>
                        <tspan textAnchor="middle" x="140" y="120.662598">{t('voucher.suffix.2')}</tspan>
                    </> : <>
                        <tspan textAnchor="middle" x="50%" y="122.662598">{t('voucher.suffix')}</tspan>
                    </>}
                </text>
            </svg>
            <g transform={manufacturerIncentives ? 'translate(342.000000, 18.000000)' : 'translate(342.000000, 50.000000)'}>
                {isFr
                    ? <>
                        <EventLogoFr
                            primaryColor={theme.primary}
                            secondaryColor={theme.textColor}
                            headingColor={'#000000'}
                            x="12.5"
                            y="-80"
                            width="150"
                        />
                    </>
                    : <>
                        <EventLogo primaryColor={theme.primary} secondaryColor={theme.textColor} x="27" y="-80" width="114" />
                        <text fontSize="7" fontWeight="normal" fill={theme.textColor}>
                            <tspan textAnchor="middle" x="85" y="5">{campaign.client.name}</tspan>
                        </text>
                    </>
                }
            </g>
        </svg>
    )
}

export default withTheme(supportedThemes)(PromoVoucher)
